@font-face {
  font-family: "League Spartan Bold";
  src: url("/assets/fonts/league-spartan-bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Geist Mono";
  src: url("/assets/fonts/GeistMonoVF.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}
