@import "../../styles/includes";

.Root {
  width: 100%;
  padding: 1rem 2rem 2rem;
  border-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  position: fixed;
  z-index: 9;

  @include media(m) {
    padding: 2rem;
  }

  &__Logo {
    width: 125px;
    height: 49px;

    svg {
      color: $white;
    }
  }
}

.Navigation {
  $root: &;

  &__Button {
    margin: 0;
    padding: 1.6rem 2rem 2rem 2.4rem;
    border: 0;
    background-color: $white;
    border-bottom-left-radius: 4rem;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 9;
    transition: 400ms ease-in-out background-color;

    @media (hover: hover) {
      &:hover {
        background-color: $yellow;
      }
    }

    #{$root}--IsExpanded & {
      background-color: $black;

      @media (hover: hover) {
        &:hover {
          background-color: $brown;
        }
      }
    }
  }

  &__Line {
    width: 4rem;
    height: 0.4rem;
    background-color: $black;
    display: block;
    margin: 0.6rem auto;
    transition: all 300ms ease-in-out;

    #{$root}--IsExpanded & {
      background-color: $white;
    }

    &:nth-child(1) {
      margin-top: 0;
    }

    &:nth-child(3) {
      margin-bottom: 0;
    }

    .Navigation--IsExpanded & {
      &:nth-child(1) {
        transform: translateY(1rem);
      }
      &:nth-child(3) {
        transform: translateY(-1rem);
      }
    }
  }

  &__Navigation {
    width: 100vw;
    height: 100vh;
    background-color: $white;
    border-bottom-left-radius: 8rem;
    position: fixed;
    right: -100vw;
    top: 0;
    z-index: 1;
    visibility: hidden;
    transition: right 300ms ease-in-out, visibility 300ms ease-in-out;

    #{$root}--IsExpanded & {
      right: 0;
      visibility: visible;
    }
  }

  &__Menu {
    height: 100%;
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.8rem;
  }

  &__Item {
    list-style: none;
    text-align: center;
  }

  &__Link {
    font-family: $font-bold;
    font-size: 5.2rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    line-height: 1;
    color: $black;
    display: inline-block;
    border: 0;
    background-color: transparent;
    transition: color $transition;

    @include media(m) {
      margin: 0 2.4rem;
      padding: 0.5rem 0rem;
      font-size: 9.6rem;
    }

    @media (hover: hover) {
      &:hover {
        color: $brown;
      }
    }
  }
}
