.Icon {
  $root: &;
  display: flex;
  align-self: center;
  align-content: center;
  flex-shrink: 0;
  pointer-events: none;

  svg {
    width: 100%;
    height: 100%;
  }
}
