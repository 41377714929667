%container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
}

%main {
  padding-top: 12rem;
  padding-bottom: 12rem;

  @include media(m) {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
}

%h1 {
  margin: 0 0 1.2rem;
  font-family: $font-bold;
  font-size: 4rem;
  color: $white;
  text-align: center;
  text-transform: uppercase;

  @include media(m) {
    margin: 0;
    font-size: 9.6rem;
  }
}

%h2 {
  margin: 0 0 2rem;
  font-family: $font-regular;
  font-size: 2.8rem;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 0.02rem;

  @include media(m) {
    font-size: 4rem;
  }
}

%p {
  font-family: $font-regular;
  font-size: 1.4rem;
  line-height: 160%;
  letter-spacing: 0.02rem;
  color: inherit;

  @include media(m) {
    font-size: 1.6rem;
  }
}

%preamble {
  font-family: $font-bold;
  font-size: 2.8rem;
  letter-spacing: 0.02rem;
  line-height: 1.2;
  text-align: center;
  color: $white;

  @include media(l) {
    font-size: 6rem;
  }

  &:not(:last-of-type) {
    margin-bottom: 3.2rem;

    @include media(l) {
      margin-bottom: 4.8rem;
    }
  }

  a {
    color: inherit;
    transition: color $transition;

    @media (hover: hover) {
      &:hover {
        color: $yellow;
      }
    }
  }
}
