@import "../../styles/includes";

.Root {
  $root: &;

  &__Title {
    @extend %h1;
    margin: 40px 0 40px;
    text-decoration: underline;
    text-underline-offset: 1.2rem;

    @include media(l) {
      margin: 120px 0 60px;
    }
  }

  &__Text {
    @extend %preamble;
  }

  &__List {
    margin: 6rem 0 0;
    display: grid;
    gap: 4rem;

    @include media(l) {
      grid-template-columns: repeat(4, 1fr);
      align-items: center;
    }
  }

  &__Item {
    @extend %p;
    font-size: 1.4rem;
    list-style: none;
    color: $white;
  }

  &__Quote {
    margin: 1.2rem 0 0;
    display: inline-block;
  }
}
