@import "../../styles/includes";

.Root {
  $root: &;

  &__Title {
    @extend %h1;
    margin: 40px 0 40px;
    text-decoration: underline;
    text-underline-offset: 1.2rem;

    @include media(l) {
      margin: 120px 0 60px;
    }
  }

  &__Text {
    @extend %preamble;
  }

  &__Section {
    margin: 0 0 4rem;

    @include media(m) {
      margin: 0 0 6rem;
    }
  }

  &__SectionTitle {
    @extend %preamble;
    margin: 0 0 10px;
    font-size: 2rem;
    text-align: left;

    @include media(l) {
      margin-bottom: 2rem;
    }
  }

  &__Grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.2rem;
    row-gap: 2rem;

    @include media(m) {
      grid-template-columns: repeat(4, 1fr);
      column-gap: 2.8rem;
      row-gap: 2.8rem;
    }
  }

  &__Item {
    position: relative;
    cursor: pointer;
  }

  &__Link {
    position: absolute;
    inset: 0;
    z-index: 1;
    cursor: pointer;
  }

  &__Image {
    aspect-ratio: 3 / 2;
    position: relative;
    transform: translate3d(0, 0, 0);
    transition: transform $transition, filter $transition;
    filter: grayscale(1);

    @media (hover: hover) {
      #{$root}__Item:hover & {
        transform: translate3d(-10px, -10px, 0);
        filter: grayscale(0);
      }
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      box-sizing: border-box;
      position: absolute;
      z-index: -1;
      transition: transform $transition;

      #{$root}__Item:hover & {
        transform: translate3d(10px, 10px, 0);
      }
    }
  }

  &__Content {
    margin: 0.8rem 0 0;
    color: $white;
    font-family: $font-regular;

    h4 {
      @extend %p;
      font-size: 1.2rem;
      letter-spacing: 0.12rem;
    }
  }
}
