@import "../../styles/includes";

.Root {
  $root: &;
  @extend %container;
  color: $white;

  &__Grid {
    padding-top: 6rem;
    padding-bottom: 8rem;
    border-top: 0.8rem solid $white;
    display: grid;
    text-align: center;

    @include media(l) {
      padding-top: 10rem;
      padding-bottom: 16rem;
      border-top: 1.2rem solid $white;
      gap: 2rem;
    }
  }

  &__Column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__Logo {
    width: 125px;
    height: 49px;

    svg {
      color: $white;
    }
  }

  &__List {
    margin: 2rem 0 0;
    font-family: $font-regular;
    font-size: 1.4rem;
    color: $white;
    list-style: none;
    display: grid;
    gap: 0.6rem;
  }

  &__Link {
    color: $white;
    text-decoration: underline;
    text-underline-offset: 0.4rem;
    transition: color $transition;

    @media (hover: hover) {
      &:hover {
        color: $yellow;
      }
    }
  }

  &__Social {
    margin: 2rem 0 0;
    display: flex;
    gap: 1rem;
  }

  &__Icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    color: $white;
    transition: transform $transition, color $transition;

    @media (hover: hover) {
      &:hover {
        color: $yellow;
        transform: scale(1.2);
      }
    }
  }
}
