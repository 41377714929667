$breakpoints: (
  s: 532px,
  m: 768px,
  l: 1024px
);

$black: #1f1a14;
$brown: #8c6239;
$blue: #4fbdba;
$yellow: #e3b505;
$white: #fffef5;

$font-bold: "League Spartan Bold", sans-serif;
$font-regular: "Geist Mono", monospace;

$transition: ease-in-out 300ms;
