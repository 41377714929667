@import "styles/includes";

.Root {
  &__Main {
    @extend %container;
    color: $white;
  }

  &__Logo {
    max-width: 1060px;
    margin: 140px auto 40px auto;

    @include media(l) {
      margin: 140px auto 120px auto;
    }
  }

  &__Intro {
    @extend %preamble;
    margin: 2.8rem 0 0;

    @include media(l) {
      margin: 5rem 0 0;
    }
  }
}
