@import "includes";

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.sr-only {
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  white-space: nowrap !important;
  text-transform: none;
  position: absolute !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  -webkit-clip-path: inset(50%) !important;
}

*::selection {
  background-color: #3e9795;
}
